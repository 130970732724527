import React,{useState,useEffect} from "react";
import logo from '../favicon.svg';
import { useUser } from "@clerk/clerk-react";
import { useClerk } from "@clerk/clerk-react";
import { getSuperBaseKey, getSuperBaseUrl } from "../Config";
import { createClient } from "@supabase/supabase-js";

import { Button, Col,Row } from "react-bootstrap";

const supabase = createClient(getSuperBaseUrl(), getSuperBaseKey());

const Header = () => {
    const { isSignedIn, user, isLoaded } = useUser();
    const [userName, setUserName] = useState<string | null>('');
    const [organization, setOrganizion] = useState<any>();
    const [logOut, setLogOut] = useState(false);
    const { signOut } = useClerk();
    const [imageUrl, setImageUrl] = useState<any[]>([])

    const fetchData = async() => {
        const { data, error } = await supabase
        .from('forManualLeadAssigment')
        .select('org_logo')
        .eq('organization_name', organization);

        data ? setImageUrl(data) : console.log(error);
    }

    useEffect(
        () => {
            if(isLoaded && isSignedIn){
                if(user){
                    setUserName(user?.username);
                    setOrganizion(user?.publicMetadata.organization_name);
                }
                fetchData();       
            }
        }, [user, fetchData]
    )
    return(
        <>
            <Row fluid>
                <Col fluid style={{padding: '10px', boxShadow: '2px 2px 2px rgba(0,0,0,0.5)', fontWeight: 'bold', display: 'flex', alignItems: 'center'}} className="d-flex justify-content-around align-items-center"> 
                    <div className="g-4 d-flex align-items-center text-muted" style={{color: "#f87171"}}>
                        <img src={logo} width={50} height={50} className="mx-4"/>MYCRM - LK
                    </div> 
                    <div className="d-flex items-center">
                        {organization && <img src={imageUrl[0]?.org_logo} width={32} height={32} className="mx-2"/>}
                        <div className="relative flex">
                            <span style={{cursor: 'pointer'}} onClick={() => {setLogOut(!logOut)}}>
                                {userName ? userName : 'DMS'}
                            </span>
                            {logOut && <div className="p-1 shadow-sm text-white rounded-3 text-center absolute" style={{background: 'red', cursor: 'pointer'}} onClick={() => {signOut({ redirectUrl : '/'})}}>Logout</div>}
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default Header;
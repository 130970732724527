import React from "react";
import { Routes, Route } from "react-router-dom";
import { Row,Col } from "react-bootstrap";
import Drawer from "./Drawer";
import Leads from "./Leads";
import Agents from "./Agents";
import Projects from "./Projects";


const Dashboard = () => {
    return(
        <Row fluid>
          <Col md={2}>
            <Drawer/>
          </Col>
          <Col fluid>
            <Routes>
                <Route path='/' element={<Leads/>}/>
                <Route path="/agents" element={<Agents/>}/>
                <Route path="/leads/" element={<Projects/>}/>
                <Route path="/:filter" element={<Projects/>}/>
                <Route path="/summary" element={<Leads/>}/>
            </Routes>
          </Col>
          
        </Row>
    );
}

export default Dashboard;
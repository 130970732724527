import React from 'react';
import logo from './logo.svg';
import './App.css';
import Header from './components/Header';
import Drawer from './components/Drawer';
import { Container, Row, Col } from 'react-bootstrap';
import { Routes, Route } from 'react-router-dom';
import { SignedIn,useUser} from '@clerk/clerk-react';
import SignIn from './SignIn';
import Dashboard from './components/Dashboard';


function App() {
  const { isSignedIn, user, isLoaded } = useUser();
  return (
    <Container style={{margin: '0px'}} fluid>
        <Header/>
        <Routes>
          <Route path='/' element={< SignIn />}/>
          <Route path='/login'  element={< SignIn />}/>
          <Route path='/dashboard/*' element={isSignedIn ? <Dashboard/> : <SignIn/>}/>
        </Routes>
        
        
       
    </Container>
  );
}

export default App;

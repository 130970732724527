import React, { useState, useEffect, useMemo } from "react";
import { Card, Container, ListGroup } from "react-bootstrap";
import { createClient } from "@supabase/supabase-js";
import { getSuperBaseUrl, getSuperBaseKey } from "../Config";
import { useUser } from "@clerk/clerk-react";

const supabase = createClient(getSuperBaseUrl(), getSuperBaseKey());

type agentMap = {
    key: any,
    leads: any[]
}

const Agents = () => {
    const [leads, setLeads] = useState<any[]>([]);
    const { isSignedIn, user, isLoaded } = useUser();
    const [mapToAgent, setMapToAgent] = useState<agentMap[]>([]);
    const [agents, setAgents] = useState<string[]>([]);

    useEffect(() => {
        FetchData();
    }, []);

    useEffect(() => {
        supabase
            .channel('schema-db-changes')
            .on(
                'postgres_changes',
                {
                    event: '*',
                    schema: 'public',
                },
                (payload) => FetchData()
            )
            .subscribe();
    }, [])

    function isArray(value: unknown): value is any[] {
        return Array.isArray(value);
    }

    const FetchData = async () => {
        let query = supabase
            .from('leads')
            .select('*')
            .gte('created_at', new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString())
            .lte('created_at', new Date().toISOString());

        if (isLoaded && isSignedIn) {
            if (user?.publicMetadata.role === "top_management") {
                query = query.eq('top_level_userName', user?.username);
            } else if (user?.publicMetadata.role === "supervisor") {
                query = query.ilike('supivisor_userName', `%${user?.username}%`);
            } else if (user?.publicMetadata.role === "saler") {
                query = query.eq('userName', user?.username);
            } else {
                return;
            }

            const { data, error } = await query.order('id', { ascending: false });

            const filteredLeads = data;

            console.log(data);
            
            
            if (filteredLeads) {
                setLeads(filteredLeads);
            }
        }
    }
    const mapAgents = (leadsToMap: any[]) => {
        let uniqueAgents = [...agents];
        for (let lead of leadsToMap) {
            let check = false;
            if (lead.userName === '' || lead.userName === null) {
                check = true;
            }
            for (let agent of uniqueAgents) {
                if (agent === lead.userName) {
                    check = true;
                    break;
                }
            }
            if (!check) {
                uniqueAgents.push(lead.userName);
            }
        }
        setAgents(uniqueAgents);
    }

    const mapToAgents = (leadsToMap: any[]) => {
        const temp: agentMap[] = [];
        for (let agent of agents) {
            const leads = leadsToMap.filter(lead => {
                if (lead.userName === agent) {
                    return true
                }
            }
            );
            temp.push({ key: agent, leads: leads });
        }
        setMapToAgent(temp)
    }

    useEffect(() => { mapAgents(leads); mapToAgents(leads) }, [leads])

    useMemo(() => { mapAgents(leads); mapToAgents(leads) }, [leads]);


    return (
        <>
            <Container className='text-center'>
                <h6 className="my-4">Agents</h6>
                <Container className="d-flex flex-wrap gap-1" fluid style={{ maxHeight: '80vh', overflowY: 'auto' }}>
                    {mapToAgent.map((i, index,) => {
                        return (
                            <Card className="text-left col" style={{ minWidth: '45%' }}>
                                <Card.Header className="text-center">{i.key}</Card.Header>
                                <ListGroup variant="flush">
                                    <ListGroup.Item className="justify-content-evenly d-flex"><span className="col-6">Leads Not Contacted</span> <span className="col-6">{i.leads.filter((lead: any) => {
                                        const dateCreated = new Date().getTime() - new Date(lead.created_at).getTime();
                                        return (dateCreated / (1000 * 60 * 60) > 8) && (lead.viewed === false);
                                    }).length}</span></ListGroup.Item>
                                    <ListGroup.Item className="justify-content-evenly d-flex"><span className="col-6" style={{ color: '#961406' }}>Follow Up Delayed</span> <span className="col-6" style={{ color: '#961406' }}>{i.leads.filter(lead => {
                                        const dateCreated = new Date();
                                        const dateFollowUp = lead.next_following_up_date ? new Date(lead.next_following_up_date) : dateCreated;
                                        const differenceInHours = (dateFollowUp.getTime() - dateCreated.getTime()) / (1000 * 60 * 60);
                                        return (differenceInHours + 2 < 0) && lead.status !== "contract_signed" && lead.status !== "declined";
                                    }).length}</span></ListGroup.Item>
                                    <ListGroup.Item className="justify-content-around d-flex"><span className="col-6">New Leads</span> <span className="col-6">{i.leads.filter(lead => {
                                        const dateCreated = new Date().getTime() - new Date(lead.created_at).getTime();
                                        return (dateCreated / (1000 * 60 * 60) <= 8) && lead.viewed === false;
                                    }).length}</span></ListGroup.Item>
                                    <ListGroup.Item className="justify-content-around d-flex"><span className="col-6">Following Up</span> <span className="col-6">{i.leads.filter(lead => {
                                        const dateCreated = new Date();
                                        const dateFollowUp = lead.next_following_up_date ? new Date(lead.next_following_up_date) : dateCreated;
                                        const differenceInHours = (dateFollowUp.getTime() - dateCreated.getTime()) / (1000 * 60 * 60);
                                        return lead.is_following_up === true && lead.status !== "contract_signed" && lead.status !== "declined" && (differenceInHours + 2 >= 0)}).length}</span></ListGroup.Item>
                                    <ListGroup.Item className="justify-content-around d-flex"><span className="col-6">Converted</span> <span className="col-6">{i.leads.filter(lead => lead.status === 'contract_signed').length}</span></ListGroup.Item>
                                    <ListGroup.Item className="justify-content-around d-flex" style={{ color: 'red' }}><span className="col-6">Declined</span> <span className="col-6" style={{ color: 'red' }}>{i.leads.filter(lead => lead.status === 'declined').length}</span></ListGroup.Item>
                                    <ListGroup.Item className="justify-content-around d-flex"><span className="col-6">Total</span> <span className="col-6">{i.leads.length}</span></ListGroup.Item>
                                </ListGroup>
                            </Card>

                        );
                    }
                    )}

                </Container>
            </Container>
        </>
    )
}

export default Agents;
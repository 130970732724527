
import React, { useEffect, useState, useMemo } from "react";
import { Button, Container, Form, DropdownButton, Dropdown, InputGroup, Row, Col } from "react-bootstrap";
import { FaSearch, FaList, FaPen } from "react-icons/fa";
import { Table } from 'antd';
import type { TableProps } from 'antd';
import FollowUpView from "./FollowUpView";
import CsvDownloader from "react-csv-downloader";

import { createClient } from "@supabase/supabase-js";
import { getSuperBaseUrl, getSuperBaseKey } from "../Config";
import { useUser } from "@clerk/clerk-react";

import AddDispositions from "./AddDispositions";
import LeadAssign from "./LeadAssign";
import { useParams } from "react-router-dom";



const supabase = createClient(getSuperBaseUrl(), getSuperBaseKey());

type agentMap = {
  key: any,
  leads: any[]
}

const Projects = () => {
  const [leads, setLeads] = useState<any[]>([]);
  const [leadNotContacted, setLeadNotContacted] = useState<any[]>([]);
  const [followupDelayed, setFollowupDelayed] = useState<any[]>([]);
  const [newLeads, setNewLeads] = useState<any[]>([]);
  const [followingUp, setFollowingUp] = useState<any[]>([]);
  const [contractSigned, setContractSigned] = useState<any[]>([]);
  const [declined, setDeclined] = useState<any[]>([]);
  const { isSignedIn, user, isLoaded } = useUser();
  const [viewId, setViewID] = useState(0);
  const [isView, setIsView] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [id, setId] = useState(0);

  const [mapToAgent, setMapToAgent] = useState<agentMap[]>([]);
  const [agents, setAgents] = useState<string[]>([]);

  const [phone, setPhone] = useState('');

  const params = useParams();

  const [searchKey, setSearchKey] = useState('');

  const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString());

  const [endDate, setEndDate] = useState(new Date().toISOString());

  const [isAssign, setAssign] = useState(false);

  const [projects, setProjects] = useState<any[]>([]);

  const formatDate = (dateC: Date) => {
    const dateNow = new Date();
    const postedDate = new Date(dateC);
    const dateMs = dateNow.getTime() - postedDate.getTime()
    const date = dateMs * 1;
    if (date < 1000) {
      return "just now";
    } else if (date >= 1000 && date < 60000) {
      return Math.ceil(date / 1000) + "seconds ago";
    } else if (date >= 60000 && date < 3600000) {
      return Math.ceil(date / 60000).toString() + " minutes ago";
    } else if (date >= 3600000 && date < 86400000) {
      return Math.ceil(date / 3600000).toString() + " hours ago";
    } else {
      return Math.ceil(date / (1000 * 3600 * 24)).toString() + " days ago"
    }
  }

  //test
  const column = [{
    id: 'agent',
    displayName: 'agent'
  },
  {
    id: 'project',
    displayName: 'campaign'
  },
  {
    id: 'date',
    displayName: 'Date'
  },
  {
    id: 'name',
    displayName: 'name'
  },
  {
    id: 'number',
    displayName: 'number'
  },
  {
    id: 'status',
    displayName: 'status'
  },

  ];


  const data = async () => {
    var leadData: {}[] = [];
    leads.forEach(lead => {
      leadData.push({ agent: lead.userName, project: lead.project, date: new Date(lead.created_at), name: lead.fullName, number: lead.phone, status: lead.status });
    });

    return leadData;
  }

  useEffect(() => {
    supabase
      .channel('schema-db-changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
        },
        (payload) => FetchData()
      )
      .subscribe();
  }, [])

  const colormap: { [key: string]: string } = {
    "Declined": "brown",
    "Following": "blue",
    "Delayed": "red",
    "Converted": "green",
    "Not Contacted": "purple",
    "New Lead": "gray",
    "": "gray"
  }


  function isArray(value: unknown): value is any[] {
    return Array.isArray(value);
  }
  const FetchData = async () => {
    let query = supabase
      .from('leads')
      .select('*')
      .gte('created_at', startDate)
      .lte('created_at', endDate);

    if (isLoaded && isSignedIn) {
      if (user?.publicMetadata.role === "top_management") {
        query = query.or(`top_level_userName.eq.${user?.username},and(userName.is.null,organization_name.eq.${user?.publicMetadata.organization_name})`);
      } else if (user?.publicMetadata.role === "supervisor") {
        query = query.or(`supivisor_userName.ilike.%${user?.username}%,and(userName.is.null,organization_name.eq.${user?.publicMetadata.organization_name})`);
      } else if (user?.publicMetadata.role === "saler") {
        query = query.or(`userName.eq.${user?.username},and(userName.is.null,organization_name.eq.${user?.publicMetadata.organization_name})`);
      } else {
        return;
      }

      const { data, error } = await query.order('id', { ascending: false });

      const filteredLeads = data;
      const projects: any[] = isArray(user?.publicMetadata.projects) ? user?.publicMetadata.projects : [];
      setProjects(projects);
      // const facebookEngagementLead = data?.filter(lead => {
      //   if (lead.lead_origin === "facebook_engagment" && projects.includes(lead.project) && user?.publicMetadata.organization_name === lead.organization_name) {
      //     return true;
      //   } else {
      //     return false;
      //   }
      // });
      if (filteredLeads) {
        setLeads(filteredLeads);
        // facebookEngagementLead?.forEach((lead: any) => {
        //   filteredLeads.push(lead);
        // });
        filterLeads(filteredLeads);
      }
    }
  }

  const filterLeadsForRendering = (lead: any) => {
    var status = "";;
    const dateCreated = new Date();
    const dateFollowUp = lead.next_following_up_date ? new Date(lead.next_following_up_date) : dateCreated;
    const differenceInHours = (dateFollowUp.getTime() - dateCreated.getTime()) / (1000 * 60 * 60);
    const date = new Date().getTime() - new Date(lead.created_at).getTime();
    if (lead.status === 'declined') {
      status = "Declined";
    } else if (lead.status === 'contract_signed') {
      status = "Converted";
    } else if (lead.is_following_up === true && lead.status !== "contract_signed" && lead.status !== "declined" && (differenceInHours + 2 >= 0)) {
      status = "Following"
    } else if (differenceInHours + 2 < 0 && lead.status !== "contract_signed" && lead.status !== "declined") {
      status = "Delayed";
    } else if ((date / (1000 * 60 * 60) <= 8) && (lead.viewed === false)) {
      status = "New Lead";
    } else if ((date / (1000 * 60 * 60) > 8) && (lead.viewed === false)) {
      status = "Not Contacted";
    }
    return status;
    // return (lead.status === 'declined' && "Declined");
    //   lead.status === 'contract_signed': return "Converted";
    // case lead.is_following_up === true && lead.status !== "contract_signed" && lead.status !== "declined": return "Following Up";
    // case differenceInHours + 2 < 0: return "Follow Up Delayed";
    // case (date / (1000 * 60 * 60) <= 8) && (lead.viewed === false): return "New Lead";
    // case (date / (1000 * 60 * 60) > 8) && (lead.viewed === false): return "Not Contacted";
    // default : return "Following Up"; 
  }

  const filterLeads = (leadsToFilter: any[]) => {
    setDeclined(leadsToFilter.filter(lead => lead.status === 'declined'));
    setContractSigned(leadsToFilter.filter(lead => lead.status === 'contract_signed'));
    setFollowupDelayed(leadsToFilter.filter(lead => {
      const dateCreated = new Date();
      const dateFollowUp = lead.next_following_up_date ? new Date(lead.next_following_up_date) : dateCreated;
      const differenceInHours = (dateFollowUp.getTime() - dateCreated.getTime()) / (1000 * 60 * 60);
      return (differenceInHours + 2 < 0) && lead.status !== "contract_signed" && lead.status !== "declined";
    }));
    setNewLeads(leadsToFilter.filter(lead => {
      const dateCreated = new Date().getTime() - new Date(lead.created_at).getTime();
      return (dateCreated / (1000 * 60 * 60) <= 8) && (lead.viewed === false);
    }));
    setFollowingUp(leadsToFilter.filter(lead => {
      const dateCreated = new Date();
      const dateFollowUp = lead.next_following_up_date ? new Date(lead.next_following_up_date) : dateCreated;
      const differenceInHours = (dateFollowUp.getTime() - dateCreated.getTime()) / (1000 * 60 * 60);
      return lead.is_following_up === true && lead.status !== "contract_signed" && lead.status !== "declined" && (differenceInHours + 2 >= 0); //
    })); //removed  && lead.status !== "lead_not_contacted"
    // setFollowingUp(leadsToFilter.filter(lead => { 
    //   const dateCreated = new Date();
    //   const dateFollowUp = lead.next_following_up_date ? new Date(lead.next_following_up_date) : dateCreated;
    //   const differenceInHours = (dateFollowUp.getTime() - dateCreated.getTime()) / (1000 * 60 * 60) ;
    //   return lead.is_following_up === true && lead.status !== "contract_signed" && lead.status !== "declined" && (differenceInHours + 2 >= 0)
    // }));
    setLeadNotContacted(leadsToFilter.filter(lead => {
      const dateCreated = new Date().getTime() - new Date(lead.created_at).getTime();
      return (dateCreated / (1000 * 60 * 60) > 8) && (lead.viewed === false);
    }));
  };
  const mapAgents = (leadsToMap: any[]) => {
    let uniqueAgents = [...agents];
    for (let lead of leadsToMap) {
      let check = false;
      for (let agent of uniqueAgents) {
        if (agent === lead.userName || lead.userName === '' || lead.userName === null) {
          check = true;
          break;
        }
      }
      if (!check) {
        uniqueAgents.push(lead.userName);
      }
    }
    setAgents(uniqueAgents);
  }
  const mapToAgents = (leadsToMap: any[]) => {
    const temp: agentMap[] = [];
    for (let agent of agents) {
      const leads = leadsToMap.filter(lead => {
        if (lead.userName === agent) {
          return true
        }
      }
      );
      temp.push({ key: agent, leads: leads });
    }
    setMapToAgent(temp)
  }

  useEffect(() => { mapAgents(leads); mapToAgents(leads) }, [leads])

  useMemo(() => { mapAgents(leads); mapToAgents(leads) }, [leads]);

  const setFilter = (filter: string) => {
    switch (filter) {
      case "allLeads": FetchData(); break;
      case "notContact": setLeads(leadNotContacted); break;
      case "followUpDelayed": setLeads(followupDelayed); break;
      case "newLeads": setLeads(newLeads); break;
      case "followingUp": setLeads(followingUp); break;
      case "contractSigned": setLeads(contractSigned); break;
      case "declined": setLeads(declined); break;
      default:
        FetchData();
    }
  }
  const columns: TableProps['columns'] = [
    {
      title: 'Name',
      dataIndex: 'fullName',
      key: 'name',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      render: (t) => <a href={`Tel:${t}`}>{t}</a>

    },
    {
      title: 'Project',
      dataIndex: 'project',
      key: 'project',
    },
    {
      title: "status",
      key: 'leadID',
      dataIndex: 'leadID',
      render: (leadID, dataSource) => {
        const status: string = filterLeadsForRendering(dataSource);
        const backgroundColor: string = colormap[status];
        return (
          <div>
            <small>
              <div style={{ background: backgroundColor, color: 'white', width: 'fit-content', opacity: 0.8 }} className="p-1 d-flex rounded">
                {status}
              </div>
              <div>
                {dataSource.userName}
              </div>
              <div>
                {formatDate(dataSource.created_at)}
              </div>
            </small>
          </div>
        );
      }
    },
    {
      title: 'dispostion Actions',
      key: 'leadID',
      dataIndex: 'leadID',
      render: (leadID, dataSource) => (
        <div className="gap-2 d-flex">
          <Button variant="primary" className="my-0" onClick={() => { setViewID(leadID); setIsAdd(false); setIsView(true); }} style={{ background: '#824D74', color: 'white', borderWidth: '0' }}><FaList /></Button>
          <Button variant="success" className="px-2 my-0" onClick={() => { setViewID(leadID); setPhone(dataSource.phone); setIsAdd(true); setIsView(false); setId(dataSource.id) }} style={{ background: '#5C3752', color: 'white', borderWidth: '0' }}><FaPen /></Button>
        </div>
      ),
    },

  ];

  const filterByProject = (project: any) => {
    setLeads(leads.filter(l => l.project === project));
  }

  const search = async (input: string) => {

    if (input.trim().length > 0) {
      let query = supabase
        .from('leads')
        .select('*')
        .gte('created_at', startDate)
        .lte('created_at', endDate).ilike('fullName', `%${input}%`);

      if (isLoaded && isSignedIn) {
        if (user?.publicMetadata.role === "top_management") {
          query = query.or(`top_level_userName.eq.${user?.username},and(userName.is.null,organization_name.eq.${user?.publicMetadata.organization_name})`);
        } else if (user?.publicMetadata.role === "supervisor") {
          query = query.or(`supivisor_userName.ilike.%${user?.username}%,and(userName.is.null,organization_name.eq.${user?.publicMetadata.organization_name})`);
        } else if (user?.publicMetadata.role === "saler") {
          query = query.or(`userName.eq.${user?.username},and(userName.is.null,organization_name.eq.${user?.publicMetadata.organization_name})`);
        } else {
          return;
        }

        const { data, error } = await query.order('id', { ascending: false });

        const filteredLeads = data;


        const projects: any[] = isArray(user?.publicMetadata.projects) ? user?.publicMetadata.projects : [];
        setProjects(projects);
        console.log(projects);
        // const facebookEngagementLead = data?.filter(lead => {
        //   if (lead.lead_origin === "facebook_engagment" && projects.includes(lead.project) && user?.publicMetadata.organization_name === lead.organization_name) {
        //     return true;
        //   } else {
        //     return false;
        //   }
        // });
        if (filteredLeads) {
          setLeads(filteredLeads);
          // facebookEngagementLead?.forEach((lead: any) => {
          //   filteredLeads.push(lead);
          // });
          filterLeads(filteredLeads);
        }
      }
    } else {
      FetchData();
    }

  }

  useEffect(() => {
    FetchData();
  }, [startDate, endDate]);

  useEffect(() => {
    switch (params.filter) {
      case "allLeads": FetchData(); break;
      case "notContact": setLeads(leadNotContacted); break;
      case "followUpDelayed": setLeads(followupDelayed); break;
      case "newLeads": setLeads(newLeads); break;
      case "followingUp": setLeads(followingUp); break;
      case "contractSigned": setLeads(contractSigned); break;
      case "declined": setLeads(declined); break;
      default:
        console.log('noparams');
    }
  }, [leads])
  return (
    <>
      <Container className="position-relative">

        <Form className="border my-2">
          <Form.Group className="mx-3 my-2">
            <Row xs={1} md={2}>
              <Col>
                <InputGroup>
                  <Form.Control type="text" placeholder="search by name..." onChange={(e) => { setSearchKey(e.target.value); search(e.target.value); }} />
                  <Button variant="primary" onClick={() => { search(searchKey) }}><FaSearch /></Button>
                </InputGroup>
              </Col>
              <Col className="d-flex justify-content-center my-2 my-sm-2 my-mb-2 my-lg-0" xs={12}>
                <InputGroup>
                  <Button variant="outline-dark">Filters</Button>
                  <DropdownButton
                    variant="outline-secondary"
                    title="Status"
                  >
                    <Dropdown.Item onClick={() => setFilter('allLeads')}>All Leads</Dropdown.Item>
                    <Dropdown.Item onClick={() => setFilter('notContact')}>Not Contacted</Dropdown.Item>
                    <Dropdown.Item onClick={() => setFilter('newLeads')}>New Leads</Dropdown.Item>
                    <Dropdown.Item onClick={() => setFilter('followingUp')}>Following up</Dropdown.Item>
                    <Dropdown.Item onClick={() => setFilter('followUpDelayed')}>Follow Up Delayed</Dropdown.Item>
                    <Dropdown.Item onClick={() => setFilter('contractSigned')}>Converted</Dropdown.Item>
                    <Dropdown.Item onClick={() => setFilter('declined')}>Declined</Dropdown.Item>
                  </DropdownButton>
                  <DropdownButton
                    variant="outline-secondary"
                    title="Agents"
                  >
                    {agents.map((agent, index) => <Dropdown.Item onClick={() => { setLeads(mapToAgent[index].leads) }}>{agent}</Dropdown.Item>)}
                  </DropdownButton>
                  <DropdownButton
                    variant="outline-secondary"
                    title="Projects"
                  >
                    {projects.map(p => <Dropdown.Item onClick={() => filterByProject(p)}>{p}</Dropdown.Item>)}
                    {/* {projects[0].map((proj:any, index:number) => <Dropdown.Item onClick={() => {setLeads(mapToAgent[index].leads)}}>{proj}</Dropdown.Item>)}                                     */}
                  </DropdownButton>
                </InputGroup>

              </Col>
            </Row>

          </Form.Group>
          <Form.Group className="mb-2 mx-3 d-flex z-1">
            <InputGroup>
              <Button variant="warning" onClick={() => setAssign(true)} size="sm">Assign</Button>
              <Form.Control
                type="date"
                defaultValue={"start date"}
                onChange={e => { setStartDate(new Date(e.target.value.toString()).toISOString()) }}
              />
              <Form.Control
                type="date"
                defaultValue={"end date"}
                onChange={e => { setEndDate(new Date(e.target.value.toString()).toISOString()) }}
              />
            </InputGroup>
            <CsvDownloader
              filename="MYCRM.LK_Leads"
              extension=".csv"
              columns={column}
              datas={data}
            >
              <Button variant="info mx-2 my-1 text-white" size="sm">Download</Button>
            </CsvDownloader>
          </Form.Group>
          
        </Form>
        <Row className="m-2">
            <p className="font-italic">Number of records : {leads.length}</p>
        </Row>
        <Container style={{ maxWidth: '100vw', overflow: 'auto', maxHeight: '80vh', scrollbarWidth: 'none' }}>
          <Table columns={columns} dataSource={leads} pagination={{ pageSize: 10 }} />
        </Container>



        <Container className="position-absolute" style={{ top: 100, zIndex: 1000 }}>
          {isView && <FollowUpView leadId={viewId} callback={() => { setIsView(false) }} />}
          {isAdd && <AddDispositions phone={phone} leadId={viewId} callback={() => { setIsAdd(false) }} agent={user?.username} id={id} />}
          {isAssign && <LeadAssign phone={phone} leadId={viewId} callback={() => { setAssign(false) }} agent={user?.username} id={id} agents={agents} projects={projects} />}
        </Container>


      </Container>
    </>
  )
}

export default Projects;
import React, {useState , useEffect} from "react";
import { Row, Col } from "react-bootstrap";
import { FaAd, FaUserFriends, FaSignOutAlt, FaChartPie } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useClerk } from "@clerk/clerk-react";
import { FaWindowClose, FaList } from "react-icons/fa";


const Switch = () => {
    const [activeId, setActiveId] = useState(1);
    const { signOut } = useClerk();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    return(
        <>
            {isOpen ? <Col fluid style={{background: '#824D74', color: 'white', fontFamily: 'sans-serif', paddingTop: '2em' , padding: '1em'}}>
                   <div style={{ display: 'flex', gap: "2em", alignItems: 'center', justifyContent:'center', width: '100%', marginTop: '2em', cursor: 'pointer', color: activeId === 1 ? 'white' : '#b0adac'}} onClick={() => {setActiveId(1); navigate('/dashboard/summary')}} className="gap-2"><FaChartPie/>Summary</div>
                   <div style={{display: 'flex', gap: "2em", alignItems: 'center', justifyContent:'center', width: '100%', marginTop: '2em' ,cursor: 'pointer', color: activeId === 2 ? 'white' : '#b0adac'}} onClick={() => {setActiveId(2); navigate('/dashboard/agents')}} className="gap-2"><FaUserFriends/>Agents</div>
                   <div style={{display: 'flex', gap: "2em", alignItems: 'center', justifyContent:'center', width: '100%', marginTop: '2em', cursor: 'pointer', color: activeId === 3 ? 'white' : '#b0adac'}} onClick={() => {setActiveId(3); navigate('/dashboard/leads')}} className="gap-2"><FaAd/>Leads</div>
                   <div style={{ display: 'flex', gap: "2em", alignItems: 'center', justifyContent:'center', width: '100%', marginTop: '2em',cursor: 'pointer', color: activeId === 4 ? 'white' : '#b0adac'}} onClick={() => {setActiveId(4); signOut({ redirectUrl : '/'})}} className="gap-2"><FaSignOutAlt/>Logout</div>
                   <div style={{ display: 'flex', gap: "2em", alignItems: 'center', justifyContent:'center', width: '100%', marginTop: '2em',cursor: 'pointer', color: 'white'}} className="font-weight-light text-center absolute bottom-0">© Copyright mycrm.lk. All Right Reserved 2024</div>
                   <div style={{ display: 'flex', gap: "2em", alignItems: 'center', justifyContent:'center', width: '100%', marginTop: '2em',cursor: 'pointer', color: 'white'}} onClick={() => {setIsOpen(false)}} className="gap-2">Close <FaWindowClose/></div>
            </Col> : <div style={{background: '#824D74', color: 'white'}} className="p-2 d-flex flex-wrap" ><FaList size={24} color="white" onClick={() => setIsOpen(true)}/></div>}
        </>
    )
}

const Drawer = () => {
    const [activeId, setActiveId] = useState(1);
    const navigate = useNavigate();
    const { signOut } = useClerk();
    const [width, setWidth] = useState<number>(window.innerWidth);

    const updateWidth = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', () => {updateWidth();})
        return(
            window.removeEventListener('resize', () => {updateWidth();})
        );
    }, [width]);
    
  
    return(
            <Row fluid className="relative">
                {width < 720 ? <Switch/> : <Col fluid style={{background: '#824D74', color: 'white', fontFamily: 'sans-serif', height: '100vh', paddingTop: '2em'}} className="relative">
                   <div style={{ display: 'flex', gap: "2em", alignItems: 'center', justifyContent:'space-around', width: '75%', marginTop: '2em', cursor: 'pointer', color: activeId === 1 ? 'white' : '#b0adac'}} onClick={() => {setActiveId(1); navigate('/dashboard/summary')}}><FaChartPie/>Summary</div>
                   <div style={{display: 'flex', gap: "2em", alignItems: 'center', justifyContent:'space-around', width: '75%', marginTop: '2em' ,cursor: 'pointer', color: activeId === 2 ? 'white' : '#b0adac'}} onClick={() => {setActiveId(2); navigate('/dashboard/agents')}}><FaUserFriends/>Agents</div>
                   <div style={{display: 'flex', gap: "2em", alignItems: 'center', justifyContent:'space-around', width: '75%', marginTop: '2em', cursor: 'pointer', color: activeId === 3 ? 'white' : '#b0adac'}} onClick={() => {setActiveId(3); navigate('/dashboard/leads')}}><FaAd/>Leads</div>
                   <div style={{ display: 'flex', gap: "2em", alignItems: 'center', justifyContent:'space-around', width: '75%', marginTop: '2em',cursor: 'pointer', color: activeId === 4 ? 'white' : '#b0adac'}} onClick={() => {setActiveId(4); signOut({ redirectUrl : '/'})}}><FaSignOutAlt/>Logout</div>
                   <div style={{ display: 'flex', gap: "2em", alignItems: 'center', justifyContent:'center', width: '100%', marginTop: '2em',cursor: 'pointer', color: 'white'}} className="font-weight-light text-center absolute bottom-0">© Copyright mycrm.lk. All Right Reserved 2024</div>
                </Col>}

                
                
            </Row>
    )
}

export default Drawer;
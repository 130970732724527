const SUPABASE_URL= "https://cesqkzvbgbrrplvqyxht.supabase.co";
const SUPABASE_KEY= 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImNlc3FrenZiZ2JycnBsdnF5eGh0Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTQ0NTE4MzcsImV4cCI6MjAzMDAyNzgzN30.600n14gALPH0BCqwubQXaN-Ruh34k-FeENficUgcJFg';

export const getSuperBaseUrl = () => { 
    return SUPABASE_URL;
}

export const getSuperBaseKey = () =>{ 
    return SUPABASE_KEY;
}


import React, { useState } from "react";
import { Container,Row, Col, Button, Toast, ToastContainer } from "react-bootstrap";
import { useSignIn } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";
import image from "./Assets/images/beta_test.png";

const SignIn = () => {
    const { isLoaded, signIn, setActive } = useSignIn();
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isToast, setIsToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const login = async() => {
        if(isLoaded){
            try {
                const signInAttempt = await signIn.create({
                  identifier: username,
                  password,
                });
                if (signInAttempt.status === 'complete') {
                  await setActive({ session: signInAttempt.createdSessionId });
                  navigate('/dashboard')
                } else {
                  setIsToast(true);
                  setToastMessage('Login Failed');
                }
              } catch (err: any) {
                console.error(JSON.stringify(err, null, 2));
              } 

        }

    }
    return(
        <>
           <Container>
                <Row style={{height: '80vh', display: 'flex', alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
                    <Col sm={6} className="text-center">
                        <h1 style={{color: '#824D74'}} className="font-weight-bold">MYCRM.LK</h1>
                        <img src={image} width={200} />
                        <form>
                            <div className="form-group my-4">
                                <input type="text" className="form-input px-4 py-1" placeholder="username" onChange={(e) => setUsername(e.target.value)} style={{borderColor: '#824D74'}}/>
                            </div>
                            <div className="form-group my-4">
                                <input type="password" className="form-input px-4 py-1" placeholder="password" onChange={(e) => setPassword(e.target.value)} style={{borderColor: '#824D74'}}/>
                            </div>
                            <div className="form-group my-4">
                                <button onClick={(e) => {e.preventDefault(); login()}} style={{borderColor: '#824D74', color: 'white', background: '#824D74', boxShadow: '2px 2px 2px rgba(0,0,0,0.5)', borderWidth: '0px'}} className="px-2 py-1 rounded">Sign In</button>
                            </div>
                        </form>
                        <ToastContainer position="top-center">
                            {
                                isToast && <Toast onClose={() => setIsToast(false)}>
                                <Toast.Header>
                                    <strong className="me-auto">MyCRM-LK</strong>
                                </Toast.Header>
                                <Toast.Body>{toastMessage}</Toast.Body>
                            </Toast>
                            }
                            
                        </ToastContainer>
                        
                    </Col>
                </Row>

           </Container>
        </>
    );  
}

export default SignIn;
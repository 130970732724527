import React, {useState, useEffect} from "react";
import { Col, Form, DropdownButton, Dropdown, Button } from "react-bootstrap";
import { FaWindowClose, FaExclamationTriangle } from "react-icons/fa";
import { createClient } from "@supabase/supabase-js";
import { getSuperBaseUrl, getSuperBaseKey } from "../Config";


const supabase = createClient(getSuperBaseUrl(), getSuperBaseKey());

type propsType = {
    leadId: number,
    phone: any,
    agent: any,
    agents: any[],
    id: number,
    projects: any[],
    callback: () => void
}

const LeadAssign = (props:propsType) => {
    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');

    const [display, setDisplay] = useState(true);

    const [filter, setFilter] = useState('');
    const [project, setProject] = useState('');

    const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString());

    const [endDate, setEndDate] = useState(new Date().toISOString());

    const updateLead = async() => {
        const currentDate = new Date();
        const dateMinus8Hours = new Date(currentDate.getTime() - 8 * 60 * 60 * 1000);
        const dateMinus2Hours = new Date(currentDate.getTime() - 2 * 60 * 60 * 1000);
        const date1 = dateMinus8Hours.toISOString();
        const date2 = dateMinus2Hours.toISOString();
        let query = supabase.from('leads').update({ userName: to, assigned_from: from }).gte('created_at', startDate).lte('created_at', endDate).eq('userName', from);
        if(filter !== 'allLeads'){  
            if(filter !== 'newLeads' && filter !== 'followUpDelayed' && filter !== 'followingUp' && filter != 'not contacted'){
                query = query.eq('status', filter);
            }else if(filter === 'newLeads'){
                query = query.gte('created_at', date1).eq('viewed', false);
            }else if(filter === 'followUpDelayed'){
                query = query.gt('next_following_up_date', date2).neq('status', 'declined').neq('status', 'contract_signed');
            }else if(filter === 'followingUp'){
                query = query.eq('is_following_up', true).neq('status', 'declined').neq('status', 'contract_signed').lte('next_following_up_date', date2);
            }else if(filter === 'not contacted'){
                query = query.lt('created_at', date1).eq('viewed', false);    
            }   
        }
        if(project){
            query = query.eq('project', project);
        }
        const {data, error} = await query.select();  
        if(data){
            alert('update succesful! affected rows : ' + data.length);
            console.log(data);
            props.callback();
        }else if(error){
            alert('update failed!');
            console.log(error);
            props.callback();
        }else{
            alert('something went wrong!');
        }
    }

    useEffect(() => {
        setDisplay(true);
    }, [])
    
    return(
        <Col md={6} className='bg-white p-4 border position-relative shadow-lg rounded position-relative' style={{maxHeight: '70vh', overflowY: 'auto', msOverflowStyle: 'none', scrollbarWidth: 'none', zIndex: 1000}} >
            <h5>Lead Assign</h5>
            {display ? <div className="w-full justify-content-center text-center background-white shadow-sm p-4"><div className="w-full mx-auto" style={{zIndex: 2001}}> 
                    <div className="d-flex justify-contetn-center align-items-center gap-4"><FaExclamationTriangle color="red" size={60}/><span>Please try to limit lead assignment changes to one time per month as this is crucial for monthly reports!</span></div>
                </div><Button onClick={() => setDisplay(false)}>I Understand</Button> </div>:
            <Form style={{zIndex: 1000}}>
                <Form.Group className="d-flex gap-2">
                    <Col className="col-6">
                        <Form.Label>From</Form.Label>
                        <DropdownButton title={from ? from : 'Select From'} style={{background: 'white', color: 'gray'}} className="border-sm rounded" variant="outline-secondary" autoClose={true} > 
                            {props.agents.map(r => <Dropdown.Item onClick={() => {setFrom(r)}}>
                                {r}</Dropdown.Item>
                                )
                            }           
                        </DropdownButton>
                    </Col>
                    <Col className="col-6">
                        <Form.Label>To</Form.Label>
                        <DropdownButton title={to ? to : 'Select To'} style={{background: 'white', color: 'gray'}} className="border-sm rounded" variant="outline-secondary" autoClose={true} > 
                            {props.agents.map(r => <Dropdown.Item onClick={() => {setTo(r)}}>
                                {r}</Dropdown.Item>
                                )
                            }           
                        </DropdownButton>
                    </Col>
                    
                </Form.Group>
                <Form.Group className="my-1 d-flex gap-2">
                    <Col className="col-6">
                        <Form.Label>Filter</Form.Label>
                        <DropdownButton
                            variant="outline-secondary"
                            title={filter ? filter : "select filter"}
                            className=""
                            >
                                <Dropdown.Item onClick={() => setFilter('allLeads')}>All Leads</Dropdown.Item>
                                <Dropdown.Item onClick={() => setFilter('not contacted')}>Not Contacted</Dropdown.Item>
                                <Dropdown.Item onClick={() => setFilter('newLeads')}>New Leads</Dropdown.Item>
                                <Dropdown.Item onClick={() => setFilter('followingUp')}>Following up</Dropdown.Item>
                                <Dropdown.Item onClick={() => setFilter('followUpDelayed')}>Follow Up Delayed</Dropdown.Item>
                                <Dropdown.Item onClick={() => setFilter('contract_signed')}>Converted</Dropdown.Item>
                                <Dropdown.Item onClick={() => setFilter('declined')}>Declined</Dropdown.Item>
                        </DropdownButton>
                    </Col>
                    <Col className="col-6">
                        <Form.Label>Projects</Form.Label>
                        <DropdownButton
                            variant="outline-secondary"
                            title={project ? project : "select project"}
                            className=""
                            >
                                {
                                    props.projects.map(p => <Dropdown.Item onClick={() => setProject(p)}>{p}</Dropdown.Item>)
                                }                                 
                        </DropdownButton>
                    </Col>
                </Form.Group>
                <Form.Group className="my-2 d-flex gap-2">
                        <Col className="col-6">
                            <Form.Label>Start Date</Form.Label>
                            <Form.Control
                                    type="date"
                                    defaultValue={"start date"}
                                    onChange={e => {setStartDate(new Date(e.target.value.toString()).toISOString())}}
                                /> 
                        </Col>
                        <Col className="col-6">
                            <Form.Label>End Date</Form.Label>
                            <Form.Control
                                type="date"
                                defaultValue={"end date"}
                                onChange={e => {setEndDate(new Date(e.target.value.toString()).toISOString())}}
                            />
                        </Col>
    
                </Form.Group>
                <Col fluid className="d-flex justify-content-center text-center">
                     <button onClick={(e) => {e.preventDefault(); updateLead();}} style={{borderColor: '#824D74', color: 'white', background: '#824D74', boxShadow: '2px 2px 2px rgba(0,0,0,0.5)', borderWidth: '0px'}} className="px-2 py-1 rounded text-center">Assign Leads</button>
                </Col>
                   
      
            </Form>
            }
            <FaWindowClose color="red" className="position-absolute top-0 end-0" onClick={props.callback}/>
            
        </Col>
    );
}

export default LeadAssign;
import React, {useState, useEffect} from "react";
import { Col, Form, DropdownButton, Dropdown} from "react-bootstrap";
import { FaWindowClose } from "react-icons/fa";
import { DatePicker, Space } from "antd";

import { createClient } from "@supabase/supabase-js";
import { getSuperBaseUrl, getSuperBaseKey } from "../Config";

const supabase = createClient(getSuperBaseUrl(), getSuperBaseKey());

type propsType = {
    leadId: number,
    phone: any,
    agent: any,
    id: number,
    callback: () => void
}

const customerReponses = [
  { label: "Interested(උනන්දුවක් දක්වයි)", value: "Interested(උනන්දුවක් දක්වයි)" },
  { label: "Not Interested(උනන්දුවක් නොදක්වයි)", value: "Not Interested(උනන්දුවක් නොදක්වයි)" },
  { label: "Budget Constraints(මුදල පිළිබඳ ගැටළුවක්)", value: "Budget Constraints(මුදල පිළිබඳ ගැටළුවක්)" },
  { label: "Call Back Later(පසුව නැවත අමතන්න)", value: "Call Back Later(පසුව නැවත අමතන්න)" },
  { label: "Not Answered(පිළිතුරු නැත)", value: "Not Answered(පිළිතුරු නැත)" },
  { label: "Wrong Number(වැරදි අංකයක්)", value: "Wrong Number(වැරදි අංකයක්)" },
  { label: "Already Purchased(දැනටමත් මිලදී ගෙන ඇත)", value: "Already Purchased(දැනටමත් මිලදී ගෙන ඇත)" },
  { label: "Callback Requested(ඇමතුමක් ඉල්ලා ඇත)", value: "Callback Requested(ඇමතුමක් ඉල්ලා ඇත)" },
];

const leadStatuses = [
  { label: "Converted(සේවාව ලබා දී ඇත)", value: "contract_signed" },
  { label: "Declined(සේවාව ප්රතික්ෂේප කර ඇත)", value: "declined" },
];

const AddDispositions = (props:propsType) => {
    const [response, setResponse] = useState('');
    const [status, setStatus] = useState('');
    const [phone, setPhone] = useState(props.phone);
    const [callDuration, setCallDuration] = useState(0);
    const [remark, setRemak] = useState('');
    const [date, setDate] = useState('');
    const [isFollowingUp, setIsFollowingUp] = useState(false);
    
    const insertDisposition = async() => {
        
        const { data, error } = await supabase
        .from('leadDispositions')
        .insert([
            { 
                phone: phone, 
                customer_response: response,
                call_duration: callDuration,
                is_following_up: isFollowingUp,
                leadID: props.leadId,
                followup_date: date,
                remark: remark,
                added_by: props.agent,
            },
        ])
        .select();
        
        const update = await updateLeads();
        await updateFirstFollowUpTime();

        if(data && update){
            console.log(data);
            props.callback();
        }else if(error){
            console.log(error?.message);
        }else{
            console.log("something went wrong")
        }
        
    }

    const updateLeads = async() => {
        const { data, error } = await supabase
        .from('leads')
        .update({ is_following_up: true, status : status, next_following_up_date: new Date(date), viewed: true})
        .eq('leadID', props.leadId)
        .select();

        if(data){
            return true
        }else{
            console.log(error.message);
            return false;
        }
    }

    const updateFirstFollowUpTime = async () => {
        const { data, error } = await supabase
        .from('leads')
        .update({ first_follow_up_time: new Date() })
        .match({ id: props.id , first_follow_up_time : '2012-12-12T00:00:00.000000+00:00'});
  
        if (error) {
          console.error('Error updating first follow-up time:', error);
        } else {
          console.log('Successfully updated first follow-up time for lead:', data);
        }
    };

    
    return(
        <Col md={6} className='bg-white p-4 border position-relative shadow-lg rounded' style={{maxHeight: '70vh', overflowY: 'auto', msOverflowStyle: 'none', scrollbarWidth: 'none'}} >
            <h5>Add Disposition</h5>
            <Form>
                <Form.Group className="d-flex gap-2">
                    <Col className="col-6">
                        <Form.Label>Phone</Form.Label>
                        <Form.Control type="text" placeholder={props.phone} onChange={(e) => {setPhone(e.target.value)}}/>
                    </Col>
                    <Col className="col-6">
                        <Form.Label>Call Duration</Form.Label>
                        <Form.Control type="number" placeholder={'0s'} onChange={(e) => {setCallDuration(parseInt(e.target.value))}}/>
                    </Col>
                    
                </Form.Group>
                <Form.Group className="my-1">
                    <Form.Label>Customer Response</Form.Label>
                    <DropdownButton title={response ? response : 'Select Response'} style={{background: 'white', color: 'gray'}} className="border-sm rounded" variant="outline-secondary" autoClose={true} > 
                        {customerReponses.map(r => <Dropdown.Item onClick={() => {setResponse(r.value)}}>
                            {r.label}</Dropdown.Item>
                            )
                        }           
                    </DropdownButton>
                </Form.Group>
                <Form.Group className="my-2">
                    <Space direction="vertical">
                        <Form.Label>Follow Up Date and Time</Form.Label>
                        <DatePicker
                            showTime
                            onChange={(value, dateString) => {
                                value && setDate(value.format('ddd, MMM D, YYYY, h:mm:ss A [GMT]Z'));
                            }}
                            onOk={() => {setIsFollowingUp(true)}}
                        />
                    </Space>
                   
                </Form.Group>
                <Form.Group>
                    <Form.Label>
                        Remark
                    </Form.Label>
                    <Form.Control type='text' placeholder="remark" onChange={e => {setRemak(e.target.value)}} />
                </Form.Group>
                <Form.Group className="my-1">
                    <Form.Label>Lead Status</Form.Label>
                    <DropdownButton title={status ? status : 'Set Status'} style={{background: 'white', color: 'gray'}} className="border-sm rounded" variant="outline-secondary" autoClose={true} > 
                        {leadStatuses.map(r => <Dropdown.Item onClick={() => {setStatus(r.value)}}>
                            {r.label}</Dropdown.Item>
                            )
                        }           
                    </DropdownButton>
                </Form.Group>
                <Col fluid className="d-flex justify-content-center text-center">
                     <button onClick={(e) => {e.preventDefault(); insertDisposition();}} style={{borderColor: '#824D74', color: 'white', background: '#824D74', boxShadow: '2px 2px 2px rgba(0,0,0,0.5)', borderWidth: '0px'}} className="px-2 py-1 rounded text-center">Save & Close</button>
                </Col>
                   
      
            </Form>
            <FaWindowClose color="red" className="position-absolute top-0 end-0" onClick={props.callback}/>
        </Col>
    );
}

export default AddDispositions;
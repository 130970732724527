import React,{useState, useEffect} from "react";
import { Col, Card } from "react-bootstrap";
import { FaWindowClose } from "react-icons/fa";
import nodata from "../Assets/images/nodata_possition.png"

import { createClient } from "@supabase/supabase-js";
import { getSuperBaseUrl, getSuperBaseKey } from "../Config";

const supabase = createClient(getSuperBaseUrl(), getSuperBaseKey());

type propsType = {
    leadId: number,
    callback: () => void
}

const FollowUpView = (props:propsType) => {
    const [dispositons, setDispostions] = useState<any[]>([]);
    const [lastFollowUp, setLastFollowUp] = useState<any>();

    const fetchDispositions = async() => {
        const { data, error } = await supabase
        .from('leadDispositions')
        .select('*').eq('leadID', props.leadId).order('id');
        if(data){
            setDispostions(data);
            setLastFollowUp(data[data.length-1]);
        }else{
          console.log(error)
        }
    }

    const followUpDate = (timestamp:any) => {

      const dateTimeString = timestamp ? timestamp.split(' GMT')[0] : '';
      // const year = date.getFullYear();
      // const month = date.getMonth();
      // const day = date.getDate();
      // const hours = date.getHours();
      // const mins = date.getMinutes();
      return dateTimeString;
      // return `${year}-${month}-${day} ${hours}:${mins}`;
    }

    useEffect(() => {fetchDispositions()}, []);
    return(
    <Col md={6} className='bg-white p-4 border position-relative shadow-lg rounded' style={{maxHeight: '70vh', overflowY: 'auto', msOverflowStyle: 'none', scrollbarWidth: 'none'}} >
      <h5>Dispositons</h5>
      {lastFollowUp ? <Card border="success">
        <Card.Header>
          Last Followup
        </Card.Header>
        <Card.Body>
          <Card.Text>Phone: {lastFollowUp.phone}</Card.Text>
          <Card.Text className="d-flex justify-content-around">
              <Col sm={6}>
                <Card.Text>Follow Up Date: {followUpDate(lastFollowUp.followup_date)}</Card.Text>
              </Col>
              <Col sm={6}>
                <Card.Text>Call Duration: {lastFollowUp.call_duration} s</Card.Text>
              </Col>
          </Card.Text>     

          <Card.Text>{lastFollowUp.customer_response && 'Customer response : ' +  lastFollowUp.customer_response}</Card.Text>
          <Card.Text>{lastFollowUp.remark && 'Remarks : ' + lastFollowUp.remark}</Card.Text>
        </Card.Body>
      </Card> : <Col className="d-flex justify-content-center align-items-center"><img src={nodata} width={200}/><p>No Dispositions Found</p></Col>
      }
      
      {dispositons.map((d, index) => 
         index !== 0 && <Card className="my-2">
         <Card.Body>
           <Card.Text>Phone: {lastFollowUp.phone}</Card.Text>
           <Card.Text className="d-flex justify-content-around">
             <Col sm={6}>
               <Card.Text>FollowUp Date: {followUpDate(d.followup_date)}</Card.Text>
             </Col>
             <Col sm={6}>
               <Card.Text>Call Duration: {d.call_duration} s</Card.Text>
             </Col>
           </Card.Text> 
           <Card.Text>{d.customer_response &&  'Customer response: ' + d.customer_response}</Card.Text>
           <Card.Text>{d.remark && 'Remarks: ' + d.remark}</Card.Text>
         </Card.Body>
       </Card>
      )}
     
      <FaWindowClose color="red" className="position-absolute top-0 end-0" onClick={props.callback}/>
    </Col>
    );
  }

  export default FollowUpView;